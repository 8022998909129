import { Injectable } from '@angular/core';
import { BehaviorSubject, timer } from 'rxjs';
import { map, share } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import { AuthService } from './auth.service';
import { DataTimeService } from '../layout/shared/service/data-time.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ExtendTimeModalComponent } from '../layout/shared/component/extend-time-modal/extend-time-modal.component';

@Injectable({
  providedIn: 'root'
})
export class AuthTimerService {

  private rxTime!: Date;
  private subscription: any;
  public remainTime$ = new BehaviorSubject<number | null>(null);
  private isModalOpen = false;
  private modalConfirm: NgbModalRef;
  public modalShown$ = new BehaviorSubject<boolean>(false);

  constructor(
    private authService: AuthService,
    private dataTimeService:DataTimeService,
    private modalService: NgbModal,
  ) { }

  startTimer() {
    this.subscription = timer(0, 1000)
      .pipe(
        map(() => new Date()),
        share()
      )
      .subscribe(time => {
        this.rxTime = time;

        if (this.doesNearlyTokenExpire()) {
          let token: Date =new Date((jwt_decode(this.authService.refreshToken) as any).exp * 1000);
          let remainingMinute = this.dataTimeService.getRemainingTime(token, this.rxTime);
          this.dataTimeService.remainTime$.next(remainingMinute);
          
          if (!this.isModalOpen) {
            this.showExpirationModal();
          }
        } else {
          if (this.isModalOpen) {
            this.modalConfirm.close();
          }
          this.closeExpirationModal();
        }
      });
  }

    doesNearlyTokenExpire(): boolean {
      return this.authService.refreshToken == null
        ? true
        : this.rxTime.getTime() + (DataTimeService.nearlyTimer) >= (jwt_decode(this.authService.refreshToken) as any).exp * 1000;
    }
  
    private showExpirationModal() {
      this.isModalOpen = true;
      this.modalShown$.next(true);
      this.modalConfirm = this.modalService.open(ExtendTimeModalComponent,{
        backdrop : 'static',
        keyboard : false
      });
      
      this.modalConfirm.result.then((x) => {
        if(x && x.confirm){
          this.authService.refresh().subscribe(x=>{
            if(x){
              this.isModalOpen = false;
            }
          })
        }
      });
    }

  private closeExpirationModal() {
    this.isModalOpen = false;
    this.modalShown$.next(false); // Notify components that modal is closed
  }

  stopTimer() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
