import { Component, OnDestroy, OnInit } from "@angular/core";
import { AuthTimerService } from "./service/auth-timer.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  title = "setworkflow";

  constructor(private authTimerService: AuthTimerService) {}

  ngOnInit() {
    this.authTimerService.startTimer();
  }

  ngOnDestroy() {
    this.authTimerService.stopTimer();
  }
}
