<div class="modal-header">
    <h4 class="modal-title">Your session will expire in {{remainingTime}} minutes.</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="canExtendTime">Do you want to extend the session?</div>
  <div class="modal-body" *ngIf="!canExtendTime">You can not extend this session.Please try to login again.</div>
  <div class="modal-footer" *ngIf="canExtendTime">
    <button type="button" class="btn btn-primary" (click)="confirm()">{{ btnConfirm }}</button>
    <button type="button" class="btn btn-outline-secondary" (click)="cancel()">CANCEL</button>
  </div>
  <div class="modal-footer" *ngIf="!canExtendTime">
    <button type="button" class="btn btn-primary" (click)="login()">LOGIN</button>

  </div>
