import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataTimeService {
  remainTime$ = new BehaviorSubject<number>(0);
  public static nearlyTimer:number = 1000 * 60 * 5; //5 min
  constructor() { }
  public getObservable() {
    return this.remainTime$.asObservable();
  }

  public getRemainingTime(token:Date,currentTime:Date):number{
    if(currentTime >= token){
      return 0;
    }else {
      return token.getMinutes() - currentTime.getMinutes();
    }
  }
}
