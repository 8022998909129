import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbCalendar, NgbDateParserFormatter, NgbModal, NgbModalOptions, NgbModalRef, } from '@ng-bootstrap/ng-bootstrap';
import { faAngleDown, faAngleUp, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import {
  DepartmentControllerService,
  DepartmentResponse,
  EmployeeControllerService,
  EmployeeResponse,
  EnumResponse,
  TaskCategoryControllerService,
  TaskCategoryResponse,
  TaskDetailControllerService,
  TaskDetailResponse,
} from '@set-it-workflow/set-it-workflow-ts-angular';
import { TaskDetailRequest } from '@set-it-workflow/set-it-workflow-ts-angular/model/taskDetailRequest';
import { fileResponse } from 'src/app/model/file.model';
import { UserProfile, UserProfileService, } from 'src/app/service/user-profile.service';
import { combineLatest, Subscription, timer } from 'rxjs';
import { roleUser } from 'src/app/constants/role.constant';
import { concatDate, toNgbDate } from '../../shared/function/to-ngb-date.function';
import { ConfirmModalComponent } from 'src/app/layout/shared/component/confirm-modal/confirm-modal.component';
import { TaskCategoryEnum } from '../../shared/constant/task-category-enum';
import { OkModalComponent } from '../../shared/component/ok-modal/ok-modal.component';
import { formLabels } from '../../../constants/form-label.constant';
import { ToastrService } from 'ngx-toastr';
import { convertStringToWorkflowStatus } from '../../shared/function/state.function';
import { AuthTimerService } from 'src/app/service/auth-timer.service';
import { take } from 'rxjs/operators';
@Component({
  selector: 'app-task-form',
  templateUrl: './task-form.component.html',
  styleUrls: ['./task-form.component.scss'],
})
export class TaskFormComponent implements OnInit, OnDestroy {
  public faAngleDown = faAngleDown;
  public faAngleUp = faAngleUp;
  public faCalendarAlt = faCalendarAlt;

  public isShowTaskCategory = false;
  public isShowRequestDetail = false;
  public isShowInvolvedPerson = false;
  public isShowAttachFile = false;
  public isShowComment = false;
  public isShowApprovalDetails = false;

  public taskCategoryList: TaskCategoryResponse[] = [];
  public form: FormGroup;
  public fileList: fileResponse[] = [];
  public userProfile: UserProfile = new UserProfile();
  private subscription: Subscription;
  public role = roleUser;
  private modalConfirm: NgbModalRef;
  public taskCategoryId: number;

  public bizUnitHeadList: EmployeeResponse[] = [];
  public bizDeptHeadList: EmployeeResponse[] = [];
  public itHeadList: EmployeeResponse[] = [];
  public solaList: EmployeeResponse[] = [];
  public uatList: EmployeeResponse[] = [];
  public userList: EmployeeResponse[] = [];
  public itList: EmployeeResponse[] = [];

  public isIssueChangId: boolean = false;
  public isIssueIncidentId: boolean = false;

  public itDeptList: DepartmentResponse[] = [];
  public serviceRequestTypeForGetDataProd: number = 4;

  public fieldsData = {
    itDepartment: null,
    serviceName: null,
    taskName: null,
    customer: null,
    requireQuotation: null,
    quotationNumber: null,
    objectiveType: null,
    scopeOfWork: null,
    benefitDescription: null,
    businessExpectedDate: null,
    businessValue: null,
    requiredQC: null,
    requestDate: null,
    sensitiveInformation: null,
    mainBusinessUser: null,
    businessUnitHead: null,
    businessDeptHead: null,
    attachment: null,
    comment: null,
    itHead: null,
    sola: null,
    uat: null,
    toSupportBusinessDepartment: null,
    estimateManDay: null,
    estimateManDayDescription:null,
    requiredUAT: null,
    productionAcceptance: null,
    serviceRequestType: null,
    dataOwner: null,
    endOfUsage: null,
    reason: null,
    changeId: null,
    incidentId: null,
    planPromoteDateTime: null,
    itHeadEr: null,
    itHeadComment: null,
    cab: null,
    cabComment: null,
    releaseDevice: null,
    postCheckRelease: null,
    toSupportBusiness: null,
    autoCreateIncidentId: null,
    srTaskId:null
  };

  public sectionData = {
    requestDetail: null,
    impactAnalysis: null,
    involvedPerson: null,
    estimateMandays: null,
    developer: null,
    developerHead: null,
    developerByDev: null,
    attachment: null,
    comment: null,
    commentHistory: null,
    nextAction: null,
    promoteDetails: null,
    approvalDetails: null,
  };

  private modalSubscription!: Subscription;
  private autoSaveSubscription!: Subscription;

  constructor(
    private router: Router,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private taskCateService: TaskCategoryControllerService,
    private taskDetailService: TaskDetailControllerService,
    private employeeService: EmployeeControllerService,
    private userService: UserProfileService,
    private modalService: NgbModal,
    private toast: ToastrService,
    private departmentService: DepartmentControllerService,
    private authTimerService: AuthTimerService,
  ) { }

  ngOnInit() {
    this.subscription = new Subscription();
    this.form = new FormGroup({
      taskCategory: new FormControl(null, Validators.required),
      itDepartment: new FormControl(),
      serviceName: new FormControl(),
      taskName: new FormControl(),
      customer: new FormControl(),
      requireQuotation: new FormControl(),
      objectiveType: new FormControl(),
      scopeOfWork: new FormControl(),
      benefitDescription: new FormControl(),
      businessExpectedDate: new FormControl(),
      businessValue: new FormControl(),
      mainBusinessUser: new FormControl(),
      businessUnitHead: new FormControl(),
      businessDeptHead: new FormControl(),
      itHead: new FormControl(),
      sola: new FormControl(),
      uat: new FormControl(),
      comment: new FormControl(),
      requestDate: new FormControl(),
      sensitiveInformation: new FormControl(),
      requiredQC: new FormControl(),
      toSupportBusinessDepartment: new FormControl(),
      estimateManDay: new FormControl(),
      mandayDescription: new FormControl(),
      requiredUAT: new FormControl(),
      productionAcceptance: new FormControl(),
      serviceRequestType: new FormControl(),
      dataOwner: new FormControl(),
      endOfUsage: new FormControl(),
      reason: new FormControl(),
      changeId: new FormControl(null,{updateOn: 'blur'}),
      incidentId: new FormControl(null,{updateOn: 'blur'}),
      planPromoteDate: new FormControl(),
      planPromoteTime: new FormControl(),
      itHeadEr: new FormControl(),
      itHeadComment: new FormControl(),
      cab: new FormControl(),
      cabComment: new FormControl(),
      releaseDevice: new FormControl(),
      postCheckRelease: new FormControl(),
      se: new FormControl(),
      toSupportBusiness:new FormControl(),
      autoCreateIncidentId: new FormControl(),
      srTaskId: new FormControl(),
      srTaskName: new FormControl(),
    });

    combineLatest([
      this.userService.userProfile$,
      this.taskCateService.getTaskCategoriesUsingGET(),
    ]).subscribe(([userProfile, taskCategory]) => {
      this.userProfile = userProfile;
      if (this.userProfile.role == this.role.user) {
        const BUType = [TaskCategoryEnum.QR.symbol, TaskCategoryEnum.BR.symbol, TaskCategoryEnum.SR.symbol];
        this.taskCategoryList = taskCategory.data.filter((t) => {
          return BUType.includes(t.taskCategorySymbol);
        });
      } else {
        this.taskCategoryList = taskCategory.data.filter(
          (t) => t.taskCategorySymbol !== TaskCategoryEnum.CR.symbol &&
            t.taskCategorySymbol !== TaskCategoryEnum.RELEASE.symbol
        );
      }
    });

    this.getInvolvedPerson();

    this.subscription.add(
      this.form.controls.taskCategory.valueChanges.subscribe((taskCategoryId) => {
        this.taskCategoryId = taskCategoryId;

        if (taskCategoryId) {
          this.getBusinessPerson();

          this.taskDetailService
            .getFormFieldByTaskCategoryAndStateUsingGET('CREATE', taskCategoryId)
            .subscribe((result) => {
              this.isShowRequestDetail = !result.data.sections.find(
                (f) => f.name === 'requestDetail'
              )?.expand;
              this.isShowInvolvedPerson = !result.data.sections.find(
                (f) => f.name === 'involvedPerson'
              )?.expand;
              this.isShowAttachFile = !result.data.sections.find(
                (f) => f.name === 'attachment'
              )?.expand;
              this.isShowComment = !result.data.sections.find(
                (f) => f.name === 'comment'
              )?.expand;
              this.isShowApprovalDetails = !result.data.sections.find(
                (f) => f.name === 'approvalDetails'
              )?.expand;

              Object.entries(this.fieldsData).forEach(([key]) => {
                this.fieldsData[key] = result.data.fields.find(
                  (f) => f.name === key
                );
                if (this.form.controls[key] && this.fieldsData[key]) {
                  this.form.controls[key].reset();
                  if (this.fieldsData[key]?.req) {
                    this.form.controls[key].setValidators(Validators.required);
                  } else {
                    this.form.controls[key].setValidators(null);
                  }
                  this.form.controls[key].updateValueAndValidity();
                  if (!this.fieldsData[key]?.editable) {
                    this.form.controls[key].disable();
                  }
                } else if (this.form.controls[key] && !this.fieldsData[key]) {
                  this.form.controls[key].reset();
                  this.form.controls[key].enable();
                  this.form.controls[key].setValidators(null);
                  this.form.controls[key].updateValueAndValidity();
                }

                if (key === 'planPromoteDateTime') {
                  this.setRequiredAndEditableToPromoteDateTime(
                    this.fieldsData[key]?.req, this.fieldsData[key]?.editable
                  );
                }
              });

              Object.entries(this.sectionData).forEach(([key]) => {
                this.sectionData[key] = result.data.sections.find(
                  (f) => f.name === key
                );
              });

              this.form.controls.requireQuotation.setValue(false);
              this.form.controls.sensitiveInformation.setValue(false);
              this.form.controls.requiredQC.setValue(false);
              this.form.controls.businessValue.setValue(0);
              // this.form.controls.businessExpectedDate.setValue(
              //   this.calendar.getToday()
              // );
              this.form.controls.planPromoteDate.setValue(this.calendar.getToday());

              if (result) {
                this.setDefaultInvolvedPerson(taskCategoryId);
                this.setDefaultRequireUAT();
              }
            });
        }
      })
    );

    this.departmentService.getITDepartmentUsingGET().subscribe((result) => {
      this.itDeptList = result.data;
    });

    this.modalSubscription = this.authTimerService.modalShown$.subscribe(isShown => {
      if (isShown) {
        this.startAutoSaveCountdown(); // Start countdown when modal appears
      } else {
        this.cancelAutoSave(); // Cancel if modal closes
      }
    });
  }

  private startAutoSaveCountdown() {
    this.autoSaveSubscription = timer(60000) // 1 minute delay
      .pipe(take(1)) // Ensure it runs only once
      .subscribe(() => {
        if (this.validateSaveDraft()) {
          this.submit('D');
        }
      });
  }

  private cancelAutoSave() {
    if (this.autoSaveSubscription) {
      this.autoSaveSubscription.unsubscribe();
    }
  }

  private getInvolvedPerson() {
    this.employeeService.getSOLAsUsingGET().subscribe((sola) => {
      this.solaList = sola.data;
    });

    this.employeeService.getAllEmployeeUsingGET().subscribe((users) => {
      this.userList = users.data;
    });

    this.employeeService.getItDevsUsingGET().subscribe((result) => {
      this.itList = result.data;
    });

    this.getItHeads();
    this.getBusinessPerson();
  }

  private getBusinessPerson() {
    if (this.userProfile.empID) {
      this.employeeService.getBusinessHeadsUsingGET(this.userProfile?.empID, 'unit').subscribe((result) => {
        this.bizUnitHeadList = result.data;
        this.setDefaultBuUnitHead();
      });

      this.employeeService.getBusinessHeadsUsingGET(this.userProfile?.empID, 'department').subscribe((result) => {
        this.bizDeptHeadList = result.data;
        this.setDefaultBuDeptHead();
      });
    }
  }

  private setDefaultInvolvedPerson(categoryId: number) {
    if (categoryId === TaskCategoryEnum.IR.id) {
      this.resetBuUnitHead();
      if (this.form.controls.requiredUAT.value === true) {
        this.setRequireUat();
        this.setRequireBuDeptHead();
        this.setDefualtUat();
        this.setDefaultBuDeptHead();
      } else if (this.form.controls.requiredUAT.value === false) {
        this.resetRequireUat();
        this.resetRequireBuDeptHead();
      }
    } else if (categoryId === TaskCategoryEnum.ER.id) {
      this.setDefaultItHead();
      this.resetRequireUat();
      this.resetRequireBuDeptHead();
    } else {
      this.setDefaultBuUnitHead();
      this.setDefaultBuDeptHead();
      this.setDefaultMainBu();
      this.setDefualtUat();
    }
  }

  private setDefaultBuUnitHead() {
    if (this.fieldsData.businessUnitHead) {
      this.form.controls.businessUnitHead.setValue(this.bizUnitHeadList[0]?.empID);
      this.form.controls.businessUnitHead.updateValueAndValidity();
    }
  }

  private setDefaultBuDeptHead() {
    if (this.fieldsData.businessDeptHead) {
      this.form.controls.businessDeptHead.setValue(this.bizDeptHeadList[0]?.empID);
      this.form.controls.businessDeptHead.updateValueAndValidity();
    }
  }

  private setDefualtUat() {
    if (this.form.controls.uat && !this.form.controls.uat.value) {
      this.form.controls.uat.setValue(this.userProfile.empID);
      this.form.controls.uat.updateValueAndValidity();
    }
  }

  private setDefaultItHead() {
    // if(this.taskCategoryId === TaskCategoryEnum.QR.id){
    //   this.form.controls.itHead.setValue(null);
    //   this.form.controls.itHead.updateValueAndValidity();
    //   return;
    // }
    if (this.form.controls.itHead && !this.form.controls.itHead.value) {
      this.form.controls.itHead.setValue(this.itHeadList[0]?.empID);
      this.form.controls.itHead.updateValueAndValidity();
    }
  }


  public convertStringToWorkflowStatus(
    state: string
  ): EnumResponse.WorkflowStatusEnum {
    return convertStringToWorkflowStatus(state);
  }

  private getItHeads(): void {
    if (this.form.controls.itDepartment.value) {
      this.employeeService.getItHeadsUsingGET(this.form.controls.itDepartment.value).subscribe((itHead) => {
        this.itHeadList = itHead.data;
      });
    }
  }

  private setRequireBuDeptHead() {
    if (this.form.controls.businessDeptHead) {
      this.fieldsData.businessDeptHead = {
        req: true,
        editable: true,
      };
      this.form.controls.businessDeptHead.setValidators(Validators.required);
      this.form.controls.businessDeptHead.updateValueAndValidity();
    }
  }

  private setRequireUat() {
    if (this.form.controls.uat) {
      this.fieldsData.uat = {
        req: true,
        editable: true,
      };
      this.form.controls.uat.setValidators(Validators.required);
      this.form.controls.uat.updateValueAndValidity();

    }
  }

  private resetBuUnitHead() {
    this.fieldsData.businessUnitHead = null;
    this.form.controls.businessUnitHead.reset();
    this.form.controls.businessUnitHead.clearValidators();
    this.form.controls.businessUnitHead.updateValueAndValidity();
  }

  private resetRequireBuDeptHead() {
    if (this.form.controls.businessDeptHead) {
      this.fieldsData.businessDeptHead = null;
      this.form.controls.businessDeptHead.reset();
      this.form.controls.businessDeptHead.clearValidators();
      this.form.controls.businessDeptHead.updateValueAndValidity();
    }
  }

  private resetRequireUat() {
    if (this.form.controls.uat) {
      this.fieldsData.uat = null;
      this.form.controls.uat.reset();
      this.form.controls.uat.clearValidators();
      this.form.controls.uat.updateValueAndValidity();

    }
  }

  private setDefaultMainBu() {
    if (!this.form.controls.mainBusinessUser.value) {
      this.form.controls.mainBusinessUser.setValue(this.userProfile.empID);
      this.form.controls.mainBusinessUser.updateValueAndValidity();
    }
  }

  private setDefaultRequireUAT() {
    this.form.controls.requiredUAT.setValue(false);
  }

  ngOnDestroy(): void {
    this.subscription && this.subscription.unsubscribe();
    if (this.modalSubscription) this.modalSubscription.unsubscribe();
    if (this.autoSaveSubscription) this.autoSaveSubscription.unsubscribe();
  }

  cancel(): void {
    this.router.navigate(['/admin']);
  }

  private setRequiredAndEditableToPromoteDateTime(req: boolean, editable: boolean) {
    if (req) {
      this.form.controls.planPromoteDate.setValidators(Validators.required);
    } else {
      this.form.controls.planPromoteDate.setValidators(null);
    }
    if (!editable) {
      this.form.controls.planPromoteDate.disable();
    } else {
      this.form.controls.planPromoteDate.enable();
    }
  }

  private setRequireField() {
    // CASE ER
    if (this.form.controls.reason.value === 2) {
      this.fieldsData.changeId = {
        req: true,
        editable: true,
      };
      this.form.controls.changeId.setValidators(Validators.required);
      this.form.controls.changeId.updateValueAndValidity();
    } else {
      this.fieldsData.changeId = {
        req: false,
        editable: true,
      };
      this.form.controls.changeId.setValidators(null);
      this.form.controls.changeId.updateValueAndValidity();
    }
  }

  private setRequireFieldSR(request: TaskDetailRequest) {
    if(this.taskCategoryId === TaskCategoryEnum.SR.id) {
      if (this.form.controls.serviceRequestType?.value === this.serviceRequestTypeForGetDataProd) {
        this.setRequiredFieldBusinessUnitHead(false, request);
        if (this.form.controls.dataOwner?.value && this.itDeptList.some(it => it.deptID === this.form.controls.dataOwner.value)) {
          this.setRequiredFieldBusinessDeptHead(false, request);
        } else {
          this.setRequiredFieldBusinessDeptHead(true, request);
        }
      } else {
        this.setRequiredFieldBusinessUnitHead(true, request);
        this.setRequiredFieldBusinessDeptHead(true, request);
      }
    }
   
  }
  private setRequiredFieldBusinessUnitHead(required: boolean, request: TaskDetailRequest) {
    if (required) {
      this.fieldsData.businessUnitHead = {
        req: true,
        editable: true,
      };
      this.form.controls.businessUnitHead.setValidators(Validators.required);
      this.form.controls.businessUnitHead.updateValueAndValidity();
    } else {
      request.businessUnitHead = null;

      this.fieldsData.businessUnitHead = {
        req: false,
        editable: true,
      };
      this.form.controls.businessUnitHead.setValidators(null);
      this.form.controls.businessUnitHead.updateValueAndValidity();
    }
  }
  private setRequiredFieldBusinessDeptHead(required: boolean, request: TaskDetailRequest) {
    if (required) {
      this.fieldsData.businessDeptHead = {
        req: true,
        editable: true,
      };
      this.form.controls.businessDeptHead.setValidators(Validators.required);
      this.form.controls.businessDeptHead.updateValueAndValidity();
    } else {
      request.businessDepartmentHead = null;

      this.fieldsData.businessDeptHead = {
        req: false,
        editable: true,
      };
      this.form.controls.businessDeptHead.setValidators(null);
      this.form.controls.businessDeptHead.updateValueAndValidity();
    }
  }

  submit(status: string): void {
    const request: TaskDetailRequest = {
      benefitDescription: this.form.controls.benefitDescription.value,
      customer: this.form.controls.customer.value,
      businessExpectedDate: this.form.controls.businessExpectedDate.value
        ? concatDate(this.form.controls.businessExpectedDate.value)
        : null,
      businessValue: this.form.controls.businessValue.value,
      componentId: this.form.controls.serviceName.value,
      itDepartmentId: this.form.controls.itDepartment.value,
      objectiveTypeId: this.form.controls.objectiveType.value,
      requireQuotation: this.form.controls.requireQuotation.value,
      scopeOfWork: this.form.controls.scopeOfWork.value,
      taskCategoryId: this.form.controls.taskCategory.value,
      taskName: this.form.controls.taskName.value,
      draft: null,
      comment: this.form.controls.comment.value,
      businessDepartmentHead: this.form.controls.businessDeptHead.value,
      businessUnitHead: this.form.controls.businessUnitHead.value,
      files: this.fileList.map((m) => ({
        fileStoreId: m.fileStoreId,
        fileName: m.fileStoreName,
      })),
      sensitiveInformation: this.form.value.sensitiveInformation,
      requiredQC: this.form.value.requiredQC,
      requiredUAT: this.form.controls.requiredUAT.value,
      toSupportBusinessDepartment:
        this.form.controls.toSupportBusinessDepartment.value ?
          this.form.controls.toSupportBusinessDepartment.value :
          this.form.controls.itDepartment.value
      ,
      estimateManDay: this.form.controls.estimateManDay.value,
      estimateManDayDescription: this.form.controls.mandayDescription.value,
      uat: this.form.controls.uat.value,
      productionAcceptance: this.form.controls.productionAcceptance.value,
      serviceRequestTypeId: this.form.controls.serviceRequestType.value,
      dataOwnerId: this.form.controls.dataOwner.value,
      endOfUsage: this.form.controls.endOfUsage.value ? 
        concatDate(this.form.controls.endOfUsage.value)
        : null,
      erReasonId: this.form.controls.reason.value,
      changeId: this.form.controls.changeId.value,
      incidentId: this.form.controls.incidentId.value,
      planPromoteDateTime: this.form.controls.planPromoteDate.value ?
        concatDate(this.form.controls.planPromoteDate.value)
        : null,
      cab: this.form.controls.cab.value,
      cabComment: this.form.controls.cabComment.value,
      itHead: this.form.controls.itHeadEr.value,
      itHeadComment: this.form.controls.itHeadComment.value,
      releaseDevice: this.form.controls.releaseDevice.value,
      postCheckRelease: this.form.controls.postCheckRelease.value,
      toSupportBusiness: this.form.controls.toSupportBusiness.value,
      autoCreateIncidentId: this.form.controls.autoCreateIncidentId.value,
      srTaskId: this.form.controls.srTaskId.value,
    };
    if (status == 'S') {
      this.setRequireField();
      this.setRequireFieldSR(request);
      if (this.form.valid && this.checkRequiredER()) {
        request.draft = false;
        this.taskDetailService
          .createTaskDetailUsingPOST(request)
          .subscribe(x => {
            this.toast.success('Submit Successfully.');
            this.router.navigate(['/admin']);

          });
      } else {
        this.form.markAllAsTouched();
        window.scrollTo(0, 0);
        this.displayErrorModal();
      }
    } else if (status == 'D') {
      if (this.validateSaveDraft()) {
        request.draft = true;
        this.taskDetailService
          .createTaskDetailUsingPOST(request)
          .subscribe(() => {
            this.toast.success('Save Successfully.');
            this.router.navigate(['/admin']);
          });
      } else {
        this.form.controls.itDepartment.markAsTouched();
        this.form.controls.serviceName.markAsTouched();
        this.form.controls.taskName.markAsTouched();
        this.displayErrorModal();
      }
    }
  }

  displayErrorModal(): void {
    const errors = ['<b class="text-danger">Please fill in a valid value for all required fields.</b>'];
    Object.keys(this.form.controls).forEach(control => {
      const valid = this.form.get(control).valid;
      const disabled = this.form.get(control).disabled;
      if (!valid && !disabled) {
        const error = formLabels.find(label => label.key === control);
        if (error) {
          errors.push('- ' + error.label);
        }
      }
    });
    if(!this.checkRequiredER()){
      errors.push('- Change Id or Incident Id is not valid.');
    }
    const modalOk = this.modalService.open(OkModalComponent);
    modalOk.componentInstance.title = 'Please verify list below:';
    modalOk.componentInstance.body = errors.join('<br/>');
  }
  validateSaveDraft(): boolean {
    return (
      this.form.controls.itDepartment.valid &&
      this.form.controls.serviceName.valid &&
      this.form.controls.taskName.valid
    );
  }

  checkRequiredER(): boolean{
    let result: boolean = true;
    
    if(this.taskCategoryId != TaskCategoryEnum.ER.id){
      return true;
    }
    if(this.form.controls.changeId.value){
      result =  result && this.isIssueChangId;
    }
    if(this.form.controls.incidentId.value){
      result =  result && this.isIssueIncidentId;
    }
    return result;
  }


}
