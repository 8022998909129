import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTimeService } from '../../service/data-time.service';

@Component({
  selector: 'app-extend-time-modal',
  templateUrl: './extend-time-modal.component.html',
  styleUrls: ['./extend-time-modal.component.scss']
})
export class ExtendTimeModalComponent implements OnInit {
  @Input() btnConfirm = 'EXTEND';
  remainingTime: number = 0;
  canExtendTime: boolean = true;
  constructor(private activeModal: NgbActiveModal
    ,private router:Router
    ,private dataTimeService:DataTimeService) {
    this.dataTimeService.getObservable().subscribe(x=> {
      this.remainingTime = x
      if(this.remainingTime == 0){
        this.canExtendTime = false;
      }
    })
  }

  ngOnInit() {}

  confirm(): void {
    this.activeModal.close({ confirm: true });
  }

  cancel(): void {
    this.activeModal.close({ confirm: false });
  }

  dismiss(): void {
    this.activeModal.dismiss();
  }
  login() : void {
    this.router.navigateByUrl('/login');
    this.activeModal.dismiss();
  }

}
